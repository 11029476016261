<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmContractGroup')" data-vv-scope="frmContractGroup" novalidate="novalidate">
      <div class="row justify-content-md-center pt-2 px-2">
        <div class="col-md-12 px-2">
          <div class="card card-fullheight">
            <div class="card-body p-2">
              <h5 class="box-title m-3">{{ $t('booking.new-edit_group') }}</h5>
              <hr>

              <!-- Operação, Origem e Destino -->
              <div class="row custom-row">
                <div class="col-md-6">
                  <div class="row custom-row">
                    <!--  Nome  -->
                    <div class="col-12 form-group px-2 ">
                      <label class="small grey-label mb-0">Nome</label>
                      <custom-input
                        v-model="group.nome"
                        name="group.nome"
                        type="text"
                        rootClassName="md-form my-0"
                        inputClassName="md-form-control text-left"
                        v-validate="{ required: true }"
                        :error="errors.first('group.nome')">
                      </custom-input>
                    </div>
                  </div>

                  <div class="row custom-row">
                    <!-- Observação -->
                    <div class="col-md-12 mt-2 px-2">
                      <label class="small grey-label mb-2">Descrição</label>
                      <custom-textarea
                        v-model="group.observacao"
                        name="group.observacao"
                        type="text"
                        rootClassName="md-form m-0"
                        inputClassName="md-form-control mt-0"
                        :rows="3"
                        v-validate="{ required: false }"
                        :error="errors.first('group.observacao')">
                      </custom-textarea>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="row custom-row">
                    <!-- Contratos de Frete -->
                    <div class="col-12 form-group px-2">
                      <label class="small grey-label mb-0">Contratos</label>
                      <custom-advanced-multiple-select
                        id-field="id"
                        labelField="nome"
                        name="group.itens"
                        class="mx-0"
                        :tableFields="contractTableFields"
                        :enableStatus="1"
                        :value="group.itens"
                        :options="contractList"
                        v-bind:fetchOptions="contractFetchOptions"
                        @onRemoved="contractRemoved"
                        @onInput="contractSelected"
                        @rowClicked="contractSelected"
                        v-validate="{ required: true }">
                      </custom-advanced-multiple-select>
                      <transition name="slide" mode="">
                        <small v-if="errors.first('group.itens')"
                          class="royalc-error-field">{{ errors.first('group.itens') }}</small>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>

              <hr>

              <div class="row custom-row">
                <!-- Submit -->
                <div class="col-12 m-0 p-0">
                  <button type="button" class="btn btn-sm btn-outline-light pull-right ml-2" @click="onSave">
                    Salvar
                  </button>
                  <button type="button" class="btn btn-link btn-sm pull-right" @click="onCancel">
                    Cancelar
                  </button>
                  <!-- <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onShow">
                    Console
                  </button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// Components
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'
import CustomInput from '@/components/Forms/CustomInput.vue'
import CustomAdvancedMultipleSelect from '@/components/Forms/CustomAdvancedMultipleSelect.vue'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Services
import BookingService from '@/services/BookingService'

export default {
  name: 'ContractGroupForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('booking.new-edit_group') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: true,
      isEdit: false,
      formErrors: {},
      fullPage: true,
      submitted: false,
      //  Selected Values (Form)
      group: {
        nome: null,
        observacao: null,
        itens: []
      },
      contractTableFields: [
        {
          name: 'id',
          title: '#',
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('origin_destination.name'),
          sortField: 'nome',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      contractList: [],
      delay: 400
    }
  },
  components: {
    Loading,
    CustomTextarea,
    CustomInput,
    CustomAdvancedMultipleSelect
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isEdit = _this.$route.name === 'BookingContractGroupEdit'
    let id = this.$route.params.id

    if (id) {
      BookingService.getBookingContractGroup(id).then(res => {
        let data = res.data.data
        let edit = this.group

        edit.id = id
        edit.nome = data.nome
        edit.observacao = data.observacao
        edit.itens = data.itens.map(x => {
          return {
            id: x.idcontrato_frete.id,
            id_table_origin: x.idcontrato_frete.id_table_origin,
            nome: x.idcontrato_frete.numero_contrato,
            status: 1
          }
        })

        this.group = edit
      })
    }

    _this.isLoading = false
  },
  methods: {
    onShow () {
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    showNumber (number) {
      return util.numberFormat(number, 2, ',', '.')
    },
    onSelectEmployee (val) {
      this.Booking.employee = val
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_Booking: object.id } }).href
    },
    contractFetchOptions (search, loading) {
      let _this = this
      loading(true)

      let filters = {
        'searchTerm': search
      }

      BookingService.getContractOptions(filters).then(res => {
        _this.contractList = res.data.data
        loading(false)
      })
    },
    contractRemoved (e) {
    },
    contractSelected (e) {
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'BookingContractGroupIndex' })
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true

          if (this.isEdit) {
            BookingService.putBookingContractGroup(this.group.id, this.group).then(response => {
              _this.$router.push({ name: 'BookingContractGroupIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            BookingService.postBookingContractGroup(this.group).then(response => {
              _this.$router.push({ name: 'BookingContractGroupIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          }
        }
      })
    },
    setOperation (obj) {
      if (this.booking.tipoOperacao.id !== obj.id) {
        this.booking.origem = {
          id: null,
          nome: null
        }
        this.booking.destino = {
          id: null,
          nome: null
        }
      }
      this.booking.tipoOperacao = obj
    },
    clearCharge () {
      if (!this.booking.possuiMulta) {
        this.booking.valorMulta = null
      }
    },
    onLostFocus () {
      if (this.booking.semana === '') {
        return
      }

      if (this.booking.semana > 52) {
        this.booking.semana = 52
      }

      if (this.booking.semana < 1) {
        this.booking.semana = 1
      }
    },
    teusOnLostFocus () {
      if (this.booking.teus < 1) {
        this.booking.teus = 1
      }
    },
    updateProcessFields () {
    }
  },
  computed: {
    equipamentsError () {
      return this.submitted && this.booking.equipamentos.length <= 0
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }

  td.editable {
    padding: 0;
    vertical-align: middle;
  }

  td.editable input {
    background-color: inherit;
    border: 0;
    width: 100%;
    min-height: 33px;
    box-sizing: border-box;
  }

  .card-subtitle {
    margin-top: 15px;
  }

</style>
